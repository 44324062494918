<template>
  <NuxtLoadingIndicator
    color="var(--brand-color)"
    :height="4"
  />
  <vNav />
  <!-- <nav>
    <RouterLink :to="{ name: 'index' }">
      <img
        src="/images/logo.png"
        alt="Stilles"
      >
    </RouterLink>
  </nav> -->
  <main>
    <slot />
  </main>
  <vSvgSprite />
</template>

<style scoped>
#__nuxt {
  display: grid;
  grid-template-rows: 84px  auto;
  min-height: 100%;
  /* transition: transform 256ms ease; */
}
nav {
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  max-width: 1488px;
  margin: 0 auto;
  position: sticky;
  top: 0;
  background-color: var(--background-color);
  z-index: 10;
}

img {
  width: 110px;
  height: auto;
  vertical-align: middle;
}
</style>
